.container {
  --cosmos-title-color: #fff;
  --navigation-dots-offset: 6%;
  --card-stack-top-card-scale: calc(1200 / 1170);
  --card-stack-card-scaling-factor: 40;
  --card-stack-card-spacing-factor: 2;

  /* The inner card-stack wrapper also adding padding/margins. 
   * This is needed because of the slides getting cropped when flying in/out */
  margin-top: var(--spacing-top); /* Dev default as part of margin refactor */
  margin-bottom: calc(var(--spacing-bottom) * 0.5);
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
  padding-inline: var(--spacing-sides);
  overflow-x: clip;

  @media (width >= 650px) {
    --navigation-dots-offset: 2%;
    --card-stack-max-width: var(--max-width-base);
  }

  @media (width >= 1000px) {
    margin-bottom: calc(var(--spacing-bottom) * 0.75);
  }
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}
